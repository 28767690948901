.card1 {
    animation: card1 15s forwards infinite, dropEffect 2s forwards;
    opacity: 0;
    z-index: 3;
    transform: scale(1.5);
      animation-timing-function: cubic-bezier(.79, .1, .12, .87), cubic-bezier(.19, .08, .01, .99);;
    animation-delay: 0s;
   
}

 
.card2 {
    animation: card1 15s forwards infinite, dropEffect 2s forwards;
    opacity: 0;
    z-index: 2;
    transform: scale(1.25);
    animation-delay: 5s, 5s;
    /* Delay for card1, dropEffect */
    animation-timing-function: cubic-bezier(.79, .1, .12, .87), cubic-bezier(.19, .08, .01, .99);
    /* Timing for card1, dropEffect */
   
}

/* Drop Effect keyframe */


.card3 {
    animation: card1 15s forwards infinite, dropEffect 2s forwards;
    
    opacity: 0;
    z-index: 1;
    transform: scale(1);
    animation-delay: 10s,10s;
      animation-timing-function: cubic-bezier(.79, .1, .12, .87), cubic-bezier(.19, .08, .01, .99);
     
}


@keyframes card1 {

    0% {
        opacity: 0;
        transform: scale(1);
        left: 340px;

        z-index: 1;
    }

    5% {
        opacity: 1;
        transform: scale(1);
        left: 340px;
        z-index: 1;
    }

    20% {
        opacity: 1;
        transform: scale(1);
        left: 340px;
        z-index: 1;
    }

    45% {
        opacity: 1;
        transform: scale(1.25);
        left: 240px;
        z-index: 2;

    }

    60% {
        opacity: 1;
        transform: scale(1.25);
        left: 240px;
        z-index: 2;

    }

    70% {
        opacity: 1;
        transform: scale(1.5);
        left: 140px;
        z-index: 3;
    }

    98% {
        opacity: 1;
        transform: scale(1.5);
        left: 140px;
        z-index: 3;
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
        left: -340px;
        z-index: 3;
    }

}

@keyframes dropEffect {
    0% {
        top: -100px;
        opacity: 1;
    }

    100% {
        top: 100px;
        opacity: 1;
    }
}