 .animate1 {
     animation: animate1 9s forwards infinite, dropEffect2 2s forwards;
     opacity: 0;
     z-index: 3;
     transform: scale(1.5);
     animation-timing-function: cubic-bezier(.99, 0, .01, .99), cubic-bezier(.19, .08, .01, .99);
     ;
     animation-delay: 0s;
 }


 .animate2 {
     animation: animate1 9s forwards infinite, dropEffect2 2s forwards;
     opacity: 0;
     z-index: 2;
     transform: scale(1.25);
     animation-delay: 3s, 3s;

     animation-timing-function: cubic-bezier(.99, 0, .01, .99), cubic-bezier(.19, .08, .01, .99);

 }


 .animate3 {
     animation: animate1 9s forwards infinite, dropEffect2 2s forwards;

     opacity: 0;
     z-index: 1;
     transform: scale(1);
     animation-delay: 6s, 6s;
     animation-timing-function: cubic-bezier(.99, 0, .01, .99), cubic-bezier(.19, .08, .01, .99);
 }

 @media only screen and (min-width: 600px) {
     @keyframes animate1 {

         0% {
             opacity: 1;
             transform: scale(1);
             left: 75%;
             z-index: 1;
         }

         5% {
             opacity: 1;
             transform: scale(1);
             left: 75%;
             z-index: 1;
         }

         20% {
             opacity: 1;
             transform: scale(1);
             left: 75%;
             z-index: 1;
         }

         45% {
             opacity: 1;
             transform: scale(1.25);
             left: 50%;
             z-index: 2;

         }

         60% {
             opacity: 1;
             transform: scale(1.25);
             left: 50%;
             z-index: 2;

         }

         70% {
             opacity: 1;
             transform: scale(1);
             left: 25%;
             z-index: 1;
         }

         98% {
             opacity: 1;
             transform: scale(1);
             left: 25%;
             z-index: 1;
         }

         100% {
             opacity: 1;
             transform: scale(1);
             left: 75%;
             z-index: 1;
         }

     }
 }

 @keyframes dropEffect2 {
     0% {
         top: -50px;
         opacity: 1;
     }

     100% {
         top: 5px;
         opacity: 1;
     }
 }



 @media only screen and (max-width: 600px) {
     @keyframes animate1 {

         0% {
             opacity: 1;
             transform: scale(1);
             left: 90%;
             z-index: 1;
         }

         5% {
             opacity: 1;
             transform: scale(1);
             left: 90%;
             z-index: 1;
         }

         20% {
             opacity: 1;
             transform: scale(1);
             left: 90%;
             z-index: 1;
         }

         45% {
             opacity: 1;
             transform: scale(1.25);
             left: 50%;
             z-index: 2;

         }

         60% {
             opacity: 1;
             transform: scale(1.25);
             left: 50%;
             z-index: 2;

         }

         70% {
             opacity: 1;
             transform: scale(1);
             left: 10%;
             z-index: 1;
         }

         98% {
             opacity: 1;
             transform: scale(1);
             left: 10%;
             z-index: 1;
         }

         100% {
             opacity: 1;
             transform: scale(1);
             left: 90%;
             z-index: 1;
         }

     }
 }