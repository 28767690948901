 

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}
