.dot {
    background: gray !important;
    opacity: 0.5 !important;
}

.dot.selected {
    background: #2A7BB4 !important;
    opacity: 1 !important;
}
.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #2A7BB4;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #2A7BB4;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}